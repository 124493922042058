import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import {FormHelperText} from '@mui/material';
import React from 'react';

interface Props {
    errorMessage: string;
	marginTop: string;
	marginBottom: string;
}

export const FieldError: React.FC<Props> = ({
	errorMessage,
	marginTop,
	marginBottom
}) => {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'start',
				marginTop: marginTop,
				marginBottom: marginBottom
			}}
		>
			<ReportProblemOutlinedIcon sx={{color: 'red', fontSize: '14px', mr: '4px'}} />
			<FormHelperText error sx={{m: 0, p: 0}}>{errorMessage}</FormHelperText>
		</div>
	);
};