import {Grid, Typography} from '@mui/material';
import React from 'react';
import {useAuth} from '../contexts/UsersContext';
import {useHandleNavigation} from '../utils/utils';
import {isSmallerThan400px, isSmallerThanMd} from '../hooks/is-compact';
import {SEO} from '../components/SEO/SEO';
import LandingPageVideo from '../assets/videos/landingpagevideo.mp4';
import {UIButton} from '../components/ui/UIButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import {useFileUpload} from '../hooks/use-file-upload';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';

export const LandingPage = () => {
	const {isLoggedIn} = useAuth();

	const {validateFileTypeAndResetState} = useFileUpload();

	const handleNavigation = useHandleNavigation();

	const smallerThan400px = isSmallerThan400px();
	const smallerThanMd = isSmallerThanMd();

	return (
		<>
			<SEO path="/"/>
			{/* Hero Section */}
			<Grid
				container
				direction="column"
				alignItems="center"
				justifyContent="start"
				sx={{
					height: '100%',
					width: '100%',
				}}
			>
				{/* Main Title */}
				<Typography
					component="h1"
					fontWeight="800"
					variant="h2"
					align="center"
					sx={{
						marginTop: 0,
						marginBottom: 2,
						px: 1,
						fontSize: smallerThanMd ? '32px' : '52px'
					}}
				>
					Your Automatic {!smallerThanMd && (<br/>)} Captions & Translations
				</Typography>

				{/* Intro text */}
				<Typography
					variant="body1"
					component="div"
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						fontWeight: '400',
						fontSize: '18px',
						px: {xs: 4, md: 2},
						mb: {xs: 3, md: 5}
					}}
					textAlign="center"
				>
					Experience the ease of automatically adding {!smallerThanMd && (<br/>)} subtitles to videos with Textualyze.
				</Typography>

				{isLoggedIn ? (
					<UIButton
						title={'Transcribe'}
						path={'/transcribe'}
						colorType="primary"
						variant="contained"
						onClick={() => {
							handleNavigation('/transcribe', true);
						}}
					>
						<ArrowForwardIcon sx={{ml: '8px', mb: '-2px'}} />
					</UIButton>
				) : (
					<div
						style={{
							display: 'flex',
							flexDirection: smallerThanMd ? 'column' : 'row',
							alignItems: 'center',
							justifyContent: 'center'
						}}
					>
						<UIButton
							title={'Upload file'}
							path={'/transcribe'}
							colorType="primary"
							variant="contained"
							fontSize={'26px'}
							component={'label'}
							onClick={() => {}}
						>
							<input
								type="file"
								hidden
								accept="video/*,audio/*"
								onChange={async (e) => {
									const files = e.target.files;
									if (!files) {
										return;
									}
									await validateFileTypeAndResetState(files[0]);
									handleNavigation('/transcribe', false);
									e.target.value = '';
								}}
							/>
							<FileUploadOutlinedIcon sx={{fontSize: '36px', ml: '1px', mr: '-5px'}} />
						</UIButton>
						<Typography
							variant="body1"
							component="div"
							sx={{
								fontWeight: '400',
								fontSize: '26px',
								mx: 2.5,
								my: 1
							}}
							textAlign="center"
						>
							or
						</Typography>
						<UIButton
							title={'Try example'}
							path={'/transcribe'}
							colorType="secondary"
							variant="contained"
							fontSize={'26px'}
							onClick={async () => {
								await handleNavigation('/transcribe?exampleVideo=true', true);
							}}
						>
							<BoltOutlinedIcon sx={{color: 'yellow', fontSize: '36px', ml: '1px', mr: '-5px'}} />
						</UIButton>
					</div>
				)}

				<Grid container item justifyContent="center" xs={12} px="5px" mt={{xs: 4, md: 6}} ml={'-12px'}>
					<video
						autoPlay
						style={{
							width: '100%',
							maxWidth: '800px',
							height: 'auto',
							borderRadius: smallerThan400px ? '16px' : '28px',
						}}
						src={LandingPageVideo}
						loop
						muted
						controlsList="nodownload"
						playsInline
					>
					</video>
				</Grid>
			</Grid>
		</>
	);
};
