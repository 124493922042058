import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {CircularProgress} from '@mui/material';
import {
	capitalize,
	displayError,
	displayInfo,
	displaySuccess,
	getPlanTypeIcon,
	isSaleActive,
	useHandleNavigation
} from '../utils/utils';
import {colors} from '../themes';
import {tryoutUserId, useAuth} from '../contexts/UsersContext';
import {subscribe} from '../api/stripe';
import {BillingInterval, findKeyByPlanType, LookupKey, PlanType, SaleInfo} from '@hendrikytt/api-contracts';
import {usePlans} from '../contexts/PlansContext';
import {useLocation} from 'react-router-dom';
import {SEO} from '../components/SEO/SEO';
import './styles.css';
import {isSmallerThanMd, isSmallerThanSm} from '../hooks/is-compact';
import {handleGAEvent} from '../lib/google-analytics';
import {UIButton} from '../components/ui/UIButton';
import {UIText} from '../components/ui/UIText';

type PricingPlan = {
	planType: PlanType,
	monthlyPrice: number,
	annualPrice: number,
	discountedMonthlyPrice: number;
	discountedAnnualPrice: number;
	monthlyFeatures: string[],
	annualFeatures: string[],
	buttonText: string,
	buttonVariant: string,
	themeColor: string,
	buttonColor: 'primary',
	textColor?: string
}

interface Props {
	saleInfo: SaleInfo | null;
}

export const PricingPage: React.FC<Props> = ({saleInfo}: Props) => {
	const location = useLocation();

	const {isLoggedIn, user} = useAuth();
	const {
		plans,
		freePlan,
		basicMonthlyPlan,
		basicAnnualPlan,
		proMonthlyPlan,
		proAnnualPlan,
		isLoadingPlans
	} = usePlans();
	const handleNavigation = useHandleNavigation();
	const isSaleCurrentlyActive = isSaleActive(saleInfo);

	const smallerThanSm = isSmallerThanSm();
	const smallerThanMd = isSmallerThanMd();

	const [isChoosePlanLoading, setIsChoosePlanLoading] = useState(false);
	const [isYearly, setIsYearly] = useState(false);

	const newlySelectedBillingInterval = useRef<BillingInterval | undefined>(undefined);
	const newlySelectedPlanId = useRef<number | undefined>(undefined);
	const newlySelectedPlanType = useRef<PlanType | undefined>(undefined);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const selectedPlanId = queryParams.get('selectedPlanId');

		const handleSelectedPlanId = async () => {
			if (selectedPlanId && user && plans.length > 0) {
				const url = new URL(window.location.href);
				url.searchParams.delete('selectedPlanId');
				window.history.replaceState(null, document.title, url.toString());

				const selectedPlan = plans.find(p => p.id === parseInt(selectedPlanId));
				if (!selectedPlan) {
					return;
				}
				newlySelectedPlanType.current = selectedPlan.plan_type;
				newlySelectedBillingInterval.current = selectedPlan.billing_interval;
				if (user.current_plan.id !== 6 && (parseInt(selectedPlanId) === user.current_plan.id || user.current_plan.id > parseInt(selectedPlanId))) {
					displayInfo('You already have premium plan');
					return;
				}
				handleGAEvent(`${newlySelectedPlanType.current!}_confirm`);
				await handleUpgradeOrPurchase();
			}
		};

		handleSelectedPlanId();
	}, []);

	const hardCodedPlans: PricingPlan[] = plans && [
		{
			planType: freePlan?.plan_type,
			monthlyPrice: freePlan?.price,
			annualPrice: freePlan?.price,
			discountedMonthlyPrice: freePlan?.discounted_price,
			discountedAnnualPrice: freePlan?.discounted_price,
			monthlyFeatures: [
				`${freePlan?.transcribed_seconds_monthly_limit} sec of captions a month`,
				`Export videos up to ${freePlan?.upload_limit_seconds} sec`,
			],
			annualFeatures: [
				`${freePlan?.transcribed_seconds_monthly_limit} sec of captions a month`,
				`Export videos up to ${freePlan?.upload_limit_seconds} sec`,
			],
			buttonText: 'Start transcribing',
			buttonVariant: 'contained',
			buttonColor: 'primary',
			textColor: colors.paper.primary,
			themeColor: colors.lightBlue
		},
		{
			planType: basicMonthlyPlan?.plan_type,
			monthlyPrice: basicMonthlyPlan?.price,
			annualPrice: basicAnnualPlan?.price,
			discountedMonthlyPrice: basicMonthlyPlan?.discounted_price,
			discountedAnnualPrice: basicAnnualPlan?.discounted_price,
			monthlyFeatures: [
				`${basicMonthlyPlan?.transcribed_seconds_monthly_limit ? basicMonthlyPlan?.transcribed_seconds_monthly_limit / 60 : 0} min of captions a month`,
				`Export videos up to ${basicMonthlyPlan?.upload_limit_seconds / 60} min`,
				`Save up to ${basicMonthlyPlan?.user_templates_count_limit} style templates`,
				`Save up to ${basicMonthlyPlan?.user_project_limit} projects`,
			],
			annualFeatures: [
				`${basicAnnualPlan?.transcribed_seconds_monthly_limit ? basicAnnualPlan?.transcribed_seconds_monthly_limit / 60 : 0} min of captions a month`,
				`Export videos up to ${basicAnnualPlan?.upload_limit_seconds / 60} min`,
				`Save up to ${basicAnnualPlan?.user_templates_count_limit} style templates`,
				`Save up to ${basicAnnualPlan?.user_project_limit} projects`,
			],
			buttonText: 'Choose plan',
			buttonVariant: 'contained',
			buttonColor: 'primary',
			textColor: colors.paper.primary,
			themeColor: colors.green
		},
		{
			planType: proMonthlyPlan?.plan_type,
			monthlyPrice: proMonthlyPlan?.price,
			annualPrice: proAnnualPlan?.price,
			discountedMonthlyPrice: proMonthlyPlan?.discounted_price,
			discountedAnnualPrice: proAnnualPlan?.discounted_price,
			monthlyFeatures: [
				`${proMonthlyPlan?.transcribed_seconds_monthly_limit ? proMonthlyPlan?.transcribed_seconds_monthly_limit / 60 : 0} min of captions a month`,
				`Export videos up to ${proMonthlyPlan?.upload_limit_seconds / 60} min`,
				`Save up to ${proMonthlyPlan?.user_templates_count_limit} style templates`,
				`Save up to ${proMonthlyPlan?.user_project_limit} projects`,
				'Add your own custom fonts',
				'Translate subtitles'
			],
			annualFeatures: [
				`${proAnnualPlan?.transcribed_seconds_monthly_limit ? proAnnualPlan?.transcribed_seconds_monthly_limit / 60 : 0} min of captions a month`,
				`Export videos up to ${proAnnualPlan?.upload_limit_seconds / 60} min`,
				`Save up to ${proAnnualPlan?.user_templates_count_limit} style templates`,
				`Save up to ${proAnnualPlan?.user_project_limit} projects`,
				'Add your own custom fonts',
				'Translate subtitles'
			],
			buttonText: 'Choose plan',
			buttonVariant: 'contained',
			buttonColor: 'primary',
			textColor: colors.paper.primary,
			themeColor: colors.gold
		},
	];

	const userHasPremiumPlan = user?.current_plan?.plan_type
		? ['BASIC', 'PRO'].includes(user?.current_plan?.plan_type as PlanType)
		: false;

	const handlePlanClick = async () => {
		if (newlySelectedPlanType.current === 'BASIC' || newlySelectedPlanType.current === 'PRO') {
			if (!isLoggedIn || !user) {
				handleNavigation(`/signup?selectedPlanId=${newlySelectedPlanId.current}`, false);
				displayInfo('You need to be authenticated first');
				return;
			}
			newlySelectedBillingInterval.current = isYearly ? 'ANNUALLY' : 'MONTHLY';
			handleGAEvent(`${newlySelectedPlanType.current!}_confirm`);
			await handleUpgradeOrPurchase();
			handleGAEvent(newlySelectedPlanType.current);
		} else {
			if (isLoggedIn) {
				handleNavigation('/transcribe', false);
			} else {
				handleNavigation('/login', false);
				displayInfo('You need to be authenticated first');
			}
		}
	};

	const handleUpgradeOrPurchase = async () => {
		if (!user || user.id === 999999 || !newlySelectedPlanType.current || !newlySelectedBillingInterval.current) {
			return;
		}
		try {
			setIsChoosePlanLoading(true);
			const data = await subscribe(
				user.email,
				newlySelectedPlanType.current,
				newlySelectedBillingInterval.current
			);
			setTimeout(() => {
				setIsChoosePlanLoading(false);
			}, 1500);
			if (data.url === '') {
				displaySuccess('Subscription updated!');
				handleNavigation('/transcribe', false);
				return;
			}
			window.location.href = data.url;
		} catch (e) {
			setIsChoosePlanLoading(false);
			displayError(e);
		}
	};

	const isCurrentPlanDisabled = (userPlanLookupKey: LookupKey | undefined, currentCardLookupKey: LookupKey) => {
		if (userPlanLookupKey === 'BASIC_MONTHLY' && currentCardLookupKey === 'BASIC_MONTHLY') {
			return true;
		}
		if (userPlanLookupKey === 'BASIC_ANNUALLY' &&
			(currentCardLookupKey === 'BASIC_MONTHLY' || currentCardLookupKey === 'BASIC_ANNUALLY' || currentCardLookupKey === 'PRO_MONTHLY')
		) {
			return true;
		}
		if (userPlanLookupKey === 'PRO_MONTHLY' &&
			(currentCardLookupKey === 'BASIC_MONTHLY' || currentCardLookupKey === 'BASIC_ANNUALLY' || currentCardLookupKey === 'PRO_MONTHLY')
		) {
			return true;
		}
		if (userPlanLookupKey === 'PRO_ANNUALLY') {
			return true;
		}
		return false;
	};

	const toggleButtonClick = () => {
		const btn = document.getElementById('btn');
		const toggleBtn = document.getElementById('toggleBtn');
		const toggleBtnYearly = document.getElementById('toggleBtnYearly');
		if (!btn || !toggleBtn || !toggleBtnYearly) {
			return;
		}
		if (btn.style.left && btn.style.left !== '0px') {
			setIsYearly(false);
			btn.style.left = '0';
		} else {
			setIsYearly(true);
			btn.style.left = '85px';
		}
	};

	return (
		<>
			<SEO path="/pricing" />
			<Box>
				<UIText variant="h1" sx={{color: 'white', textAlign: 'center', mb: '10px', lineHeight: '1.2'}}>
					Simple, transparent pricing
				</UIText>
				<Grid container alignItems="center" justifyContent="center" mb={3}>
					<Typography
						variant="body1"
						align="center"
					>
						Choose the plan that fits your needs
					</Typography>
				</Grid>
				<Grid container alignItems="center" justifyContent="center" mt={2} mb={smallerThanSm ? 4 : 14}>
					<Grid item>
						<div className="button-box">
							<div id="btn"></div>
							<button type="button" className="toggle-btn" id="toggleBtn" onClick={toggleButtonClick}>
								<Typography
									fontWeight={isYearly ? '500' : '700'}
									fontSize="12px"
									sx={{
										color: isYearly ? '#000' : '#fff',
										transform: 'translateX(-2px)',
									}}
								>
									MONTHLY
								</Typography>
							</button>
							<button type="button" className="toggle-btn" id="toggleBtnYearly" onClick={toggleButtonClick}>
								<Typography
									fontWeight={!isYearly ? '500' : '700'}
									fontSize="12px"
									sx={{
										color: !isYearly ? '#000' : '#fff',
										transform: 'translateX(-2px)',
									}}
								>
									YEARLY
								</Typography>
							</button>
						</div>
					</Grid>
				</Grid>

				{isLoadingPlans && (
					<Grid container alignItems="center" justifyContent="center">
						<CircularProgress/>
					</Grid>
				)}
				{!isLoadingPlans && (
					<Grid container columnSpacing={smallerThanMd ? 0 : 1} sx={{
						backgroundColor: colors.paper.primary,
						borderRadius: '8px',
						py: '20px',
						px: '0px',
						mt: smallerThanMd ? '0px' : '48px'
					}}>
						{hardCodedPlans.map((plan) => {
							const price = isYearly ? plan.annualPrice * 12 : plan.monthlyPrice;
							const discountedPrice = isYearly ? plan.discountedAnnualPrice * 12 : plan.discountedMonthlyPrice;
							const saveAmount = isYearly ?
								(plan.annualPrice - plan.discountedAnnualPrice) * 12
								:
								plan.monthlyPrice - plan.discountedMonthlyPrice;
							const features = isYearly ? plan.annualFeatures : plan.monthlyFeatures;
							const isCurrentPlanPurchased = isYearly
								? user?.current_plan?.plan_type === plan.planType && user?.current_plan.billing_interval === 'ANNUALLY' && user?.id !== tryoutUserId
								: user?.current_plan?.plan_type === plan.planType && user?.current_plan.billing_interval === 'MONTHLY' && user?.id !== tryoutUserId;
							const currentLookupKey = isYearly
								? `${plan.planType}_ANNUALLY`
								: `${plan.planType}_MONTHLY`;
							return (
								<Grid item xs={12} md={4} key={plan.monthlyFeatures.join('')} sx={{
									mb: smallerThanMd ? '20px' : '0'
								}}>
									<Box
										sx={{
											backgroundColor: colors.paper.primary,
											height: (plan.planType === 'BASIC' && !smallerThanMd) ? '112%' : '104%',
											display: 'grid',
											gridTemplateRows: '25px 40px 80px 180px auto',
											rowGap: '0px',
											alignItems: 'start',
											justifyContent: 'center',
											py: 3,
											mx: '0px',
											transform: plan.planType === 'BASIC' ? `translateY(-${smallerThanMd ? '5' : '65'}px) translateX(${smallerThanMd ? 0 : -24}px)` : 'none',
											boxShadow: plan.planType === 'BASIC' ? `0px ${smallerThanMd ? '0' : '42'}px 34px 0px rgba(82, 67, 194, 0.30)` : 'none',
											borderRadius: '8px',
											pl: 0,
											width: !smallerThanMd && plan.planType === 'BASIC' ? '112%' : '100%'
										}}
									>

										<Box sx={{ gridRow: '1 / 2', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', alignSelf: 'start', position: 'relative' }}>
											{plan.planType !== 'FREE' && isSaleCurrentlyActive && (
												<Box sx={{
													backgroundColor: colors.yellow,
													color: 'black',
													px: '16px',
													py: '7px',
													borderRadius: '26px',
													boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.3)',
													typography: 'subtitle2',
													position: 'absolute',
													top: `${smallerThanMd ? '-10' : '0'}px`,
													right: '-10px'
												}}>
													<Typography fontSize="12px" sx={{ whiteSpace: 'nowrap' }}>
														<strong>
															{`YOU SAVE $${saveAmount}!`}
														</strong>
													</Typography>
												</Box>
											)}
										</Box>

										<Box sx={{gridRow: '2 / 3', display: 'flex', justifyContent: 'start', alignItems: 'center', alignSelf: 'start'}}>
											{getPlanTypeIcon(plan.planType, '38px')}
											<UIText
												variant="h3"
												component="h2"
												ml="6px"
											>
												{capitalize(plan.planType)}
											</UIText>
										</Box>
										<Box sx={{gridRow: '3 / 4', display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: '20px'}}>
											{plan.planType === 'FREE' && (
												<UIText
													variant="h2"
													component="h2"
													mr="6px"
												>
													${price}
												</UIText>
											)}
											{plan.planType !== 'FREE' && (
												<UIText
													variant="h2"
													component="h2"
													mr="6px"
												>
													<span style={{ textDecoration: isSaleCurrentlyActive ? 'line-through' : 'none', color: isSaleCurrentlyActive ? '#C8C8C8' : 'black', fontSize: isSaleCurrentlyActive ? '20px': '32px'}}>
        												${price}
													</span>
													{isSaleCurrentlyActive ? ` $${discountedPrice}` : ''}
												</UIText>
											)}
											<Typography component="h3" fontWeight="500" fontSize="17px" variant="h6" color="black">
												{isYearly ? '/year': '/month'}
											</Typography>
										</Box>

										<Box sx={{gridRow: '4 / 5', alignSelf: 'start'}}>
											{features.map((feature) => (
												<Box key={feature} sx={{ display: 'flex', alignItems: 'center', textAlign: 'left', gap: '8px', mb: '8px' }}>
													<div
														style={{
															borderRadius: '72px',
															background: plan.themeColor,
															width: '20px',
															height: '20px',
															display: 'flex',
															flexDirection: 'column',
															justifyContent: 'center',
															alignItems: 'center',
															gap: '10px'
														}}
													>
														<svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none" style={{
															width: '11.467px',
															height: '9.433px',
															flexShrink: '0',
														}}>
															<path d="M11.5485 1.64038L5.84438 9.38121C5.70834 9.5617 5.50548 9.67997 5.28139 9.70943C5.05731 9.73889 4.83078 9.67708 4.65271 9.53788L0.579376 6.28121C0.219931 5.99357 0.161728 5.46899 0.449376 5.10955C0.737025 4.7501 1.2616 4.6919 1.62104 4.97955L5.01771 7.69705L10.2069 0.654547C10.377 0.399205 10.6738 0.258032 10.9792 0.287078C11.2847 0.316124 11.5495 0.510688 11.6685 0.793524C11.7875 1.07636 11.7414 1.4017 11.5485 1.64038Z"
																  fill={plan.planType === 'BASIC' ? '#067051' : '#0A0D59'}/>
														</svg>
													</div>
													<UIText
														variant="small"
														component="h4"
														color={'#716F83'}
													>
														{feature}
													</UIText>
												</Box>
											))}
										</Box>
										<Box sx={{gridRow: '5 / 6', alignSelf: 'end', display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
											{plan.planType === 'FREE' && (
												<UIButton
													title={isCurrentPlanPurchased ? 'Current plan' : plan.buttonText}
													colorType={plan.buttonColor}
													variant="contained"
													onClick={async () => {
														newlySelectedPlanType.current = plan.planType;
														const billingInterval = isYearly ? 'ANNUALLY' : 'MONTHLY';
														newlySelectedPlanId.current = findKeyByPlanType(newlySelectedPlanType.current, billingInterval);
														await handlePlanClick();

														if (typeof window.gtag_report_conversion === 'function') {
															window.gtag_report_conversion();
														}
													}}
													disabled={
														isCurrentPlanPurchased ||
														userHasPremiumPlan ||
														isCurrentPlanDisabled(user?.current_plan?.lookup_key, currentLookupKey as LookupKey) ||
														(newlySelectedPlanType.current !== plan.planType && isChoosePlanLoading)
													}
													backgroundColor="transparent"
													borderColor={userHasPremiumPlan ? 'transparent' : colors.palette.primary}
													textColor={colors.palette.primary}
													isLoading={newlySelectedPlanType.current === plan.planType && isChoosePlanLoading}
													loadingSpinnerColor={colors.palette.primary}
													style={{
														width: '210px'
													}}
												/>
											)}
											{plan.planType !== 'FREE' && (
												<UIButton
													title={isCurrentPlanPurchased ? 'Current plan' : plan.buttonText}
													colorType={plan.buttonColor}
													variant="contained"
													onClick={async () => {
														newlySelectedPlanType.current = plan.planType;
														const billingInterval = isYearly ? 'ANNUALLY' : 'MONTHLY';
														newlySelectedPlanId.current = findKeyByPlanType(newlySelectedPlanType.current, billingInterval);
														await handlePlanClick();

														if (typeof window.gtag_report_conversion === 'function') {
															window.gtag_report_conversion();
														}
													}}
													disabled={
														isCurrentPlanPurchased ||
														isCurrentPlanDisabled(user?.current_plan?.lookup_key, currentLookupKey as LookupKey) ||
														(newlySelectedPlanType.current !== plan.planType && isChoosePlanLoading)
													}
													isLoading={newlySelectedPlanType.current === plan.planType && isChoosePlanLoading}
													style={{
														width: '210px'
													}}
												/>
											)}
										</Box>
									</Box>
								</Grid>
							);
						})}
					</Grid>
				)}
			</Box>
		</>
	);
};