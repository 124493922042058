import {getPlans} from '../api/plans';
import {displayError, displayInfo} from '../utils/utils';
import {createContext, FC, ReactNode, useContext, useEffect, useState} from 'react';
import {Plan} from '@hendrikytt/api-contracts';
import {listenToSocketEvent} from '../api/sockets';

const makeContext = <T,>(render: () => T) => {
	const MyContext = createContext<T>({} as T);

	const useMyContext = () => useContext(MyContext);

	const MyProvider: FC<{ children: ReactNode }> = ({ children }) => {
		const value = render();
		return <MyContext.Provider value={value}>{children}</MyContext.Provider>;
	};

	return [MyProvider, useMyContext] as const;
};

export const [PlansProvider, usePlans] = makeContext(() => {
	const [isLoadingPlans, setIsLoadingPlans] = useState(true);
	const [plans, setPlans] = useState<Plan[]>([]);

	const fetchPlans = async () => {
		try {
			const plans = await getPlans();
			if (plans.length === 0) {
				displayInfo('Plans are not available currently. Please try again later.');
				return;
			}
			setPlans(plans);
		} catch (e) {
			displayError(e);
		}
		setIsLoadingPlans(false);
	};

	const init = async () => {
		await fetchPlans();
		listenToSocketEvent('plans-update', async () => {
			await fetchPlans();
		}, null, null);
	};

	useEffect(() => {
		init();
	}, []);

	const freePlan = plans[0];
	const basicMonthlyPlan = plans[1];
	const basicAnnualPlan = plans[2];
	const proMonthlyPlan = plans[3];
	const proAnnualPlan = plans[4];

	return {
		plans,
		fetchPlans,
		isLoadingPlans,
		freePlan,
		basicMonthlyPlan,
		basicAnnualPlan,
		proMonthlyPlan,
		proAnnualPlan
	};
});