import {SEO} from '../components/SEO/SEO';
import React from 'react';
import {Grid, Typography} from '@mui/material';
import OurTeamLandscape from '../assets/images/our_team_landscape.png';
import OurTeamPortrait from '../assets/images/our_team_portrait.png';
import {isSmallerThanSm} from '../hooks/is-compact';
import {UIButton} from '../components/ui/UIButton';

export const OurTeamPage = () => {

	const smallerThanSm = isSmallerThanSm();
	
	return (
		<>
			<SEO path="/our-team" />
			<Grid container justifyContent="center">
				<Typography fontWeight="700" component="h1" variant="h3" fontSize="40px" gutterBottom align="center">
                    Our Team
				</Typography>
			</Grid>
			<Grid container justifyContent="center">
				<Typography component="h2" variant="subtitle1" textAlign="center">
					Meet people who are building Textualyze
				</Typography>
			</Grid>
			<Grid mt={smallerThanSm ? 4 : 10}>
				<Typography component="p" variant="body1" textAlign="start" mb={1}>
					First, there’s Hendrik. He’s our techincal wizard, the guy who makes the magic happen behind the scenes.
				</Typography>
				<Typography component="p" variant="body1" textAlign="start" mb={1}>
					Then there’s Hanna. She’s like the mom of the group, always there to make sure everything looks and feels just right. She is the genius behind our website’s design and flow.
				</Typography>
				<Typography component="p" variant="body1" textAlign="start" mb={2}>
					And then there&apos;s Kaarup. The one who handles all the odds and ends, doing the tasks that nobody else wants to do. From writing blog posts to editing videos, he’s got it covered.
				</Typography>
				<Typography component="p" variant="body1" textAlign="start" mb={2}>
					Together, we&apos;re a solid team, each bringing our own skills and expertise to make Textualyze the best it can be.
				</Typography>
			</Grid>
			<Grid container item justifyContent="center" xs={12}>
				<img src={smallerThanSm ? OurTeamPortrait : OurTeamLandscape} alt="Our Team Picture" style={{ width: '100%', maxWidth: '1468px', height: 'auto', borderRadius: '5px' }} />
			</Grid>
			<Grid container justifyContent="center" mt={2}>
				<UIButton
					title="Support Us"
					// path="https://donate.stripe.com/test_dR66q51llaKlg0g145"
					path="https://donate.stripe.com/4gw9DZ8YW44X1Lq28a"
					colorType="primary"
					variant="contained"
					onClick={() => {
						window.open('https://donate.stripe.com/4gw9DZ8YW44X1Lq28a', '_blank');
						// window.open('https://donate.stripe.com/test_dR66q51llaKlg0g145', '_blank');
					}}
				/>
			</Grid>
		</>
	);
};
