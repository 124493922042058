import {CircularProgress, Grid, Typography} from '@mui/material';
import {colors} from '../../themes';
import React, {useState} from 'react';
import {useDialogContext} from '../../contexts/DialogContext';
import {useTranscriptionContext} from '../../contexts/TranscriptionContext';
import {displayError, displayInfo, useHandleNavigation} from '../../utils/utils';
import {useAuth} from '../../contexts/UsersContext';
import {UIDialog} from '../ui/UIDialog';
import {UIButton} from '../ui/UIButton';

export const AreYouSureLogoutDialog = () => {
	const {
		logout,
		setIsDrawerOpen
	} = useAuth();
	const {isLogoutDialogOpen, setIsLogoutDialogOpen} = useDialogContext();

	const handleNavigation = useHandleNavigation();
	const {setWillProgressBeLost} = useTranscriptionContext();

	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async () => {
		try {
			setWillProgressBeLost(false);
			await logout();
		} catch (e) {
			displayError(e);
		}
		setIsLogoutDialogOpen(false);
		setIsDrawerOpen(false);
		displayInfo('Logged out');
		await handleNavigation('/', false);
		setIsLoading(false);
	};

	return (
		<UIDialog
			open={isLogoutDialogOpen}
			title="Are you sure you want to log out?"
			content={
				<Grid container direction="row" alignItems="center">
					<Grid item>
						<Typography variant="h6" component="div">
							You will lose all progress
						</Typography>
					</Grid>
				</Grid>
			}
			actions={isLoading ? (
				<Grid container alignItems="center" justifyContent="center">
					<CircularProgress/>
				</Grid>
			) : (
				<>
					<UIButton
						title="No"
						colorType="primary"
						variant="text"
						onClick={() => {setIsLogoutDialogOpen(false);}}
						textColor={colors.font.secondary}
					/>
					<UIButton
						title="Yes"
						colorType="primary"
						variant="text"
						onClick={handleSubmit}
						textColor={colors.font.secondary}
					/>
				</>
			)}
		/>
	);
};